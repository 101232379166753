exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-matrix-jsx": () => import("./../../../src/pages/matrix.jsx" /* webpackChunkName: "component---src-pages-matrix-jsx" */),
  "component---src-pages-resistor-jsx": () => import("./../../../src/pages/resistor.jsx" /* webpackChunkName: "component---src-pages-resistor-jsx" */),
  "component---src-pages-rgb-converter-jsx": () => import("./../../../src/pages/rgb-converter.jsx" /* webpackChunkName: "component---src-pages-rgb-converter-jsx" */),
  "component---src-pages-toolbox-js": () => import("./../../../src/pages/toolbox.js" /* webpackChunkName: "component---src-pages-toolbox-js" */),
  "component---src-templates-arduino-js": () => import("./../../../src/templates/arduino.js" /* webpackChunkName: "component---src-templates-arduino-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-list-js": () => import("./../../../src/templates/article-list.js" /* webpackChunkName: "component---src-templates-article-list-js" */),
  "component---src-templates-espressif-js": () => import("./../../../src/templates/espressif.js" /* webpackChunkName: "component---src-templates-espressif-js" */),
  "component---src-templates-ia-js": () => import("./../../../src/templates/ia.js" /* webpackChunkName: "component---src-templates-ia-js" */),
  "component---src-templates-labmaker-js": () => import("./../../../src/templates/labmaker.js" /* webpackChunkName: "component---src-templates-labmaker-js" */),
  "component---src-templates-raspberry-js": () => import("./../../../src/templates/raspberry.js" /* webpackChunkName: "component---src-templates-raspberry-js" */),
  "component---src-templates-web-js": () => import("./../../../src/templates/web.js" /* webpackChunkName: "component---src-templates-web-js" */)
}

